import React from "react"
import { Styled, css } from "theme-ui"
import PostFooter from "gatsby-theme-blog/src/components/post-footer"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

const p = props => (
  <div
    css={css({
      margin: ['0 0', '0 0', '0 auto'],
      maxWidth: '700px',
    })}
  >
    <Styled.p css={css({ marginLeft: [0, 0, "5em"], marginRight: [ 0, 0, "5em" ]})} {...props} />
  </div>
)

const components = {
  p,
}

const Post = ({
  data: {
    post,
    site: {
      siteMetadata: { title },
    },
  },
  location,
  previous,
  next,
}) => (
  <Layout location={location} title={title}>
    <SEO title={post.title} description={post.excerpt} />
    <main css={{maxWidth: '1366px', margin: '0 auto'}}>
      <div>
        <Styled.h1 style={{ marginBottom: "2em" }}>{post.title}</Styled.h1>
      </div>
      <MDXProvider components={components}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
    </main>
    <PostFooter {...{ previous, next }} />
  </Layout>
)

export default Post
